<template>
  <div class="home" id="home">
    <div class="tabs" style="height:100%;">
      <v-card style="height:100%;">
        <v-tabs v-model="tab" light color="#4D78AE" height="40px" @change="tanChange">
          <v-tab @click="tabsClick(0)">推荐</v-tab>
          <v-tab v-for="(item,index) in items" :key="index" @click="tabsClick(item.id)">{{ item.name }}</v-tab>
        </v-tabs>
        <div class="wrapper" ref="wrapper" style="height:100%;overflow: hidden;">
          <!-- 首页 -->
          <BSView v-show="isRecommend" :config="configOne" :isEnd='isEndOne' @pullingDown="pullingDownFunOne()" @PullUp="PullUpFunOne()" :isLoading='isLoading' :isPullDisplay="isPullDisplayOne">
            <div class="content">
              <!-- 列表 -->
              <!-- <ChartView :dataList="dataList"></ChartView> -->
              <div class="dataList">
                <!-- 第一个卡片 -->
                <div class="dataListOne">
                  <v-card class="mx-auto" color="#FF6D00" dark max-width="400">
                    <v-card-title>
                      <img style="width:32px" src="../../public/image/1.png" alt="">
                      <!-- <v-icon large left >
                        mdi-twitter
                      </v-icon> -->
                      <span class="font-weight-light">
                        每日头条
                      </span>
                    </v-card-title>
                    <v-card-text class="headline font-weight-bold" @click="into(oneItem.id)">
                      <!-- 恭喜南宁公司2020年签约业绩突破100亿元，加入百亿城市俱乐部！ -->
                      {{oneItem != ''?oneItem.title:'无数据'}}
                    </v-card-text>
                    <v-card-actions>
                      <v-list-item class="grow">
                        <v-list-item-avatar color="grey darken-3"  v-for="(items,indexs) in oneItem.avator" :key="indexs">
                          <v-img class="elevation-6" :src="items" ></v-img>
                        </v-list-item-avatar>
                        <span class="YD"><span v-if="oneItem.count!=0">等</span>{{oneItem.count}}人已读</span>
                      </v-list-item>
                    </v-card-actions>
                  </v-card>
                </div>
                <ul v-if="dataList.length != 0">
                  <!-- read 为1就是已读 -->
                  <li v-for="(item,index) in dataList" :key="index" @click="into(item.id,index)" :class="[item.read==1?'active':'noactive']">                    
                    <div class="dataListContent">{{item?item.title:''}}</div>
                    <!-- 图表1 -->
                    <chartOne v-if="item.chat_type==2 && item.chart_data!=null" :data='item.chart_data'></chartOne>
                    <!-- 图表2 -->
                    <chartTwo v-if="item.chat_type==1 && item.chart_data!=null" :data='item.chart_data'></chartTwo>
                    <!-- 描述 -->                    
                    <div class="dataListDes">
                      <!-- <span>{{item.send_time}}</span> -->
                      <!-- <span>{{item.time[0].d=='0'&&item.time[1].h=='0'&&item.time[2].m=='0'&&item.time[3].s=='0'?'刚刚': (item.time[0].d=='0'&&item.time[1].h=='0'&&item.time[2].m=='0'?item.time[3].s+'秒前':(item.time[0].d=='0'&&item.time[1].h=='0'?item.time[2].m+'分钟前': (item.time[0].d!='0'?item.time[0].d+'天前':item.time[1].h+'小时前')))}}</span> -->
                       <span>{{ item.send_time | splitSendTime() }}</span>
                      <span v-if="item.type==3">测试</span>
                      <span>{{item.group_name}}</span>
                      <span style="color:#FF6D00">{{item.me?"与我相关":''}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </BSView>
          <!-- 预警 -->
          <BSView v-show="!isRecommend" :config="configTwo" :isEnd='isEndTwo' @pullingDown="pullingDownFunTwo()" @PullUp="PullUpFunTwo()" :isLoading='isLoading' :isPullDisplay="isPullDisplay">
            <div class="content">
              <div class="dataList" v-if="dataListTwo.length != 0">
                <ul>
                  <li v-for="(item,index) in dataListTwo" :key="index" :class="[item.read==1?'active':'noactive']">
                    <!-- <div class="warnBox" v-show="index==0">
                      <div class="warnCard">
                        <div class="warnCardTitle">有35条预警信息未查看</div>
                        <v-sheet v-if="sheetState" class="mx-auto" :max-width="maxWidth">
                          <v-slide-group multiple>
                            <v-slide-item v-for="(item,index) in wranNoList" :key="index">
                              <div class="warnCardContent">
                                <p>{{item.value}}</p>
                                <p>{{item.title}}</p>
                              </div>
                            </v-slide-item>
                          </v-slide-group>
                        </v-sheet>
                      </div>
                    </div> -->
                    
                    <div class="dataListContent"  @click="into(item.id,index)">{{item?item.title:''}}</div>
                    <!-- 图表1 -->
                    <chartOne v-if="item.chat_type==2 && item.chart_data!=null" :data='item.chart_data'></chartOne>
                    <!-- 图表2 -->
                    <chartTwo v-if="item.chat_type==1 && item.chart_data!=null" :data='item.chart_data'></chartTwo>             
                    <div class="dataListDes">
                      <span>{{ item.send_time | splitSendTime() }}</span>
                      <!-- <span>{{item.time[0].d=='0'&&item.time[1].h=='0'&&item.time[2].m=='0'&&item.time[3].s=='0'?'刚刚': (item.time[0].d=='0'&&item.time[1].h=='0'&&item.time[2].m=='0'?item.time[3].s+'秒前':(item.time[0].d=='0'&&item.time[1].h=='0'?item.time[2].m+'分钟前': (item.time[0].d!='0'?item.time[0].d+'天前':item.time[1].h+'小时前')))}}</span> -->

                      <span v-if="item.type==3">测试</span>
                      <span>{{item.group_name}}</span>
                      <span style="color:#FF6D00">{{item.me?"与我相关":''}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </BSView>
        </div>
      </v-card>
    </div>
    <loding class="loading" v-if="isLoading"></loding>
  </div>
</template>

<script>
import BSView from "@/components/BSView.vue";
import chartOne from "@/components/chartOne.vue";
import chartTwo from "@/components/chartTwo.vue";
import imgevery from '../../public/image/everdayimg.jpg'
import loding from '../components/loding.vue'
import md5 from 'js-md5'


export default {
  name: "Home",
  components: {
    BSView,
    chartOne,
    chartTwo,
    loding
  },
  data() {
    return {
      isLoading: false,
      isPullDisplay:true,
      isPullDisplayOne:true,
      oneItem:'',
      everyImg:require('../../public/image/everdayimg.jpg'),
      defaultavatar:require('../../public/image/2.png'),//默认头像
      everdayImg:[//每日头条已读头像
        require('../../public/image/2.png'),
        require('../../public/image/3.png'),
        require('../../public/image/4.jpg')
      ],
      sheetState:false,//控制预警未查看组件，不控制初始化的时候滑动会失效
      maxWidth:'',//未查看预警卡片的宽度
      model: null,
      configOne: {
        //滚动条
        id: "BSViewOne",
        bs: "",
        style: {
          bottom: 0,
          top: "41px",
          width: "100%"
        },
        disabledDown: false,
        disabledUp: false,
      },
      isEndOne:false,
      isEndTwo:false,
      configTwo: {
        //滚动条
        id: "BSViewTwo",
        bs: "",
        style: {
          bottom: 0,
          top: "41px",
          width: "100%"
        },
        disabledDown: false,
        disabledUp: false,
      },
      everdayList: [], //存储的日期值
      isRecommend: true, //是否是推荐
      tab: null,
      items: [
        // { tab: "推荐", content: "Tab 1 Content" },
        // { tab: "预警", content: "Tab 2 Content" },
        // { tab: 'Three', content: 'Tab 3 Content' },
      ],
      timeDataTab: null,
      timeDateList: [
        //销售监控数据实时监控
        // { tab: "当日", content: "Tab 1 Content" },
        // { tab: "当月", content: "Tab 2 Content" },
        // { tab: "当年", content: "Tab 2 Content" },
      ],
      dataList:[],
      dataListTwo:[],
      wranNoList:[//预警未查看的数据列表

      ],
      cur_page:1,//当前页面
      cur_count:7,//显示条数
      cur_pageTwo:1,//当前页面
      cur_countTwo:7,//显示条数
      tagId:''
    };
  },
  watch: {
    '$route':'fetchData'
  },
  filters: {
    splitSendTime(date) {
      return date.slice(0, -3)
    }
  },
  methods: {
    fetchData() {
      if(this.$route.path == '/') {
        document.title = '数加加'
        this.getTabList()
        if(!$core.getLocal('HomeId')) {
          let now = new Date();
          let nowTime = now.getTime();//获取当前时间时间戳
          $core.setLocal('HomeId',this.CalcuMD5(String(nowTime)))
          
          if($core.getLocal('value') == '报表') {
            this.getUserActive('进入报表','进入报表')
          }else {
            if($core.getUrlKey("isArticle")) {
              this.getUserActive('进入主页','消息进入主页')
            }else {
              this.getUserActive('进入主页','应用进入主页')
            }
          }
        }
      }
    },
    pullingDownFunOne() {//下拉刷新
      var that = this
      setTimeout(() => {
        this.configOne.disabledDown = true;
        that.cur_page = 1
        $core.request('front/index/list', res => {
          if(res.status == 200) {
            if(res.data.length == 0) {
              that.isEndOne = true
            }else {
              that.isEndOne = false
                that.dataList = res.data
            }
            if(that.dataList.length<7) {
            // console.log("数据显示第一条")
              that.isPullDisplayOne = false
            }else {
              that.isPullDisplayOne = true
            }
            that.$nextTick(()=> {
              that.configOne.bs.refresh();
            })
          }
          if(res.status == 0) {
            if(res.data.length == 0) {
              that.isEndOne = true
            }
          }
          if(res.code == 401) {
            console.log(res.resultMsg)
          }        
      },{
        'cur_page':that.cur_page,
        'cur_count':that.cur_count
      });
      }, 1000);
    },
    pullingDownFunTwo() {//下拉刷新
      var that = this
      that.cur_pageTwo = 1
      setTimeout(() => {
        this.configTwo.disabledDown = true;
        if(that.tagId != 0) {
          $core.request('front/index/list', res => {
            if(res.status == 200) {
              if(res.data.length == 0) {
                that.isEndTwo = true
              }else {
                that.isEndTwo = false
                  that.dataListTwo=res.data
              }
              if(that.dataList.length<7) {
              // console.log("数据显示第一条")
                that.isPullDisplayOne = false
              }else {
                that.isPullDisplayOne = true
              }
              that.$nextTick(()=> {
                that.configTwo.bs.refresh();
              })
            }
            if(res.status == 0) {
              if(res.data.length == 0) {
                that.isEndTwo = true
              }
            }
            if(res.code == 401) {
              console.log(res.resultMsg)
            }        
          },{
            'cur_page':that.cur_pageTwo,
            'cur_count':that.cur_countTwo,
            'tag':that.tagId
          });
        }
      }, 1000);
    },
    PullUpFunOne() {//上拉加载
      let that = this
      setTimeout(() => {
        this.configOne.disabledUp = true;
        this.cur_page = this.cur_page + 1
        that.getDataList()
      }, 1000)
    },
    PullUpFunTwo() {
      let that = this
      setTimeout(() => {
        that.configTwo.disabledUp = true;
        that.cur_pageTwo = that.cur_pageTwo + 1
        that.getDataTwoList(this.tagId)
      }, 3000)
    },
    tanChange(num) {
      this.dataList = []
      this.dataListTwo = []
    },
    async tabsClick(id) {//tab选项卡切换
      // console.log(id)
      this.tagId = id
      this.isLoading = true
      if (id == 0) {
        this.isRecommend = true;
        this.getDataList()
      } else {
        this.isRecommend = false;
        this.dataListTwo = []
        this.isEndTwo = false
        this.cur_pageTwo = 1//当前页面
        if(this.dataListTwo.length == 0) {
          // console.log(this.dataListTwo)
          await this.getDataTwoListTwo(id,true)
        }        
      }

      this.$nextTick(()=>{
        if (id != 0) {
          this.sheetState=false//控制预警未查看组件，不控制初始化的时候滑动会失效
          this[['configOne','configTwo'][1]].bs.once('refresh', () => {
            this.sheetState=true
          })
          // this.pullingDownFunTwo();
        }
        this.configOne.bs.refresh();
        this.configTwo.bs.refresh();
      })
    },
    into(id,index) {//跳转到详情
      $core.clearLocal("statementSrc")
      $core.clearLocal('HomeId')
      this.$router.push({
        //核心语句
        path: "/ArticleDetails", //跳转的路径
        query: {//路由传参时push和query搭配使用 ，作用时传递参数
          wechat_id:$core.getUrlKey('wechat_id'),
          id: id,
          isHome:'yes'
        },
      });
      this.dataList[index].read = 1
      this.$forceUpdate()
    },
    wxAuthorization() {//微信授权，获取token
      if(!$core.getLocal($core.getUrlKey('wechat_id'))) {
        if(!$core.getUrlKey('token')) {
            window.location.href = "https://jiajia1.aitaiyi.cn/api/wechat/wx_oauth?wechat_id="+$core.getUrlKey('wechat_id')+"&back_url="+location.href.split('#')[0]
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }else {
            $core.setLocal($core.getUrlKey('wechat_id'),$core.getUrlKey('token'))
            $core.setLocal('token',$core.getUrlKey('token'))
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }
      }else {
        if($core.getLocal($core.getUrlKey('wechat_id')) != $core.getLocal('token')) {
          if(!$core.getUrlKey('token')) {
            window.location.href = "https://jiajia1.aitaiyi.cn/api/wechat/wx_oauth?wechat_id="+$core.getUrlKey('wechat_id')+"&back_url="+location.href.split('#')[0]
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }else {
            $core.setLocal($core.getUrlKey('wechat_id'),$core.getUrlKey('token'))
            $core.setLocal('token',$core.getUrlKey('token'))
            $core.setLocal('wechat_id',$core.getUrlKey('wechat_id'))
          }
        }
      }

      
    },
    getDataList() {//获取消息列表
      $core.request('front/index/list', res => {
        // console.log(res)
        if(res.status == 200) {
          if(res.data.length == 0) {
            this.isEndOne = true
          }else {
            this.isEndOne = false
            for(let i in res.data) {
              this.dataList.push(res.data[i])
            }
          }
          // console.log()
          if(this.dataList.length<7) {
            // console.log("数据显示第一条")
            this.isPullDisplayOne = false
          }else {
            this.isPullDisplayOne = true
          }
          this.$nextTick(()=> {
            this.configOne.bs.refresh();
            this.configTwo.bs.refresh();
          })
          
        }
        if(res.status == 0) {
          if(res.data.length == 0) {
            this.isEndOne = true
          }
        }
        if(res.code == 401) {
          console.log(res.resultMsg)
        }        
        this.isLoading = false
      },{
        'cur_page':this.cur_page,
        'cur_count':this.cur_count
      });
    },
    getListOne() {
      $core.request('front/index/hot_msg', res => {
        // console.log(res)
        if(res.code == 401) {
          this.oneItem = ''
        }else {
          if(res.data.length == 0) {
            this.oneItem = ''
          }else {
            this.oneItem = res.data[0]
          }
          
        }
        
      },{
      });
    },
    getTabList() {
      $core.request('front/index/tag', res => {
        // console.log(res)
        if(res.status == 200) {
          // console.log(res.data)
          this.items = res.data
        }
        
      },{
      });
    },
    getDataTwoList(tagId,val) {//获取消息列表
      this.isPullDisplay = true
      if(tagId != 0) {
        $core.request('front/index/list', res => {
          // console.log(this.dataListTwo)
          if(res.status == 200) {
            if(res.data.length == 0) {
              this.isEndTwo = true
            }else {
              this.isEndTwo = false
              if(val) {
                this.dataListTwo = []
              }
              for(let i in res.data) {
                this.dataListTwo.push(res.data[i])
              }
            }
            if(this.dataListTwo.length<8) {
              this.isPullDisplay = false
            }else {
              this.isPullDisplay = true
            }
            this.$nextTick(()=> {
              this.configOne.bs.refresh();
              this.configTwo.bs.refresh();
            })
            this.isLoading = false   
          }
          if(res.status == 0) {
            if(res.data.length == 0) {
              this.isEndTwo = true
              this.isLoading = false   
            }
          }
          if(res.code == 401) {
            console.log(res.resultMsg)
            this.isLoading = false   
          }  
          // this.isLoading = false      
        },{
          'cur_page':this.cur_pageTwo,
          'cur_count':this.cur_countTwo,
          'tag':tagId
        });
      }
    },
    getDataTwoListTwo(tagId,val) {//获取消息列表
      this.isPullDisplay = true
      if(tagId != 0) {
        $core.request('front/index/list', res => {
          // console.log(this.dataListTwo)
          if(res.status == 200) {
            if(res.data.length == 0) {
              this.isEndTwo = true
            }else {
              this.isEndTwo = false
              if(val) {
                this.dataListTwo = []
              }
              this.dataListTwo=res.data
              // for(let i in res.data) {
              //   this.dataListTwo.push(res.data[i])
              // }
            }
            if(this.dataListTwo.length<7) {
              this.isPullDisplay = false
            }else {
              this.isPullDisplay = true
            }
            this.$nextTick(()=> {
              this.configOne.bs.refresh();
              this.configTwo.bs.refresh();
            })
            this.isLoading = false   
          }
          if(res.status == 0) {
            if(res.data.length == 0) {
              this.isEndTwo = true
              this.isLoading = false   
            }
          }
          if(res.code == 401) {
            console.log(res.resultMsg)
            this.isLoading = false   
          }     
          
        },{
          'cur_page':this.cur_pageTwo,
          'cur_count':this.cur_countTwo,
          'tag':tagId
        });
      }
    },
    CalcuMD5(pwd) {
      let num = String(Math.floor(Math.random() * (10000 - 1)) + 1)
      pwd = md5(num+pwd);
      return pwd;
    },
    getUserActive(name,event) {
      $core.request('growdata/add', res => {
        // console.log(res)
      },{
        'id':'',//消息id
        "page_id":$core.getLocal('HomeId'),//页面id
        'name':encodeURI(name),//事件类型名称
        'event':encodeURI(event),//事件
      });
    },
  },
  mounted() {
    this.wxAuthorization()
    $core.setLocal('homeurl',location.href.split('#'))
    this.getTabList()
    this.isLoading = true
    this.getDataList()
    this.getListOne()

    // // 滚动条底部的定位
    this.configOne.style.bottom = window.document.getElementById("tabsBox").children[0].clientHeight + "px";
    this.configTwo.style.bottom = window.document.getElementById("tabsBox").children[0].clientHeight + "px";
    this.maxWidth = Number(window.document.getElementsByTagName('body')[0].offsetWidth) - 55
  },
  created() {
    // 给当前页面创建唯一id
    if(!$core.getLocal('HomeId')) {
      let now = new Date();
      let nowTime = now.getTime();//获取当前时间时间戳
      $core.setLocal('HomeId',this.CalcuMD5(String(nowTime)))
      if($core.getLocal('value') == '报表') {
        this.getUserActive('进入报表','进入报表')
      }else {
        if($core.getUrlKey("isArticle")) {
          this.getUserActive('进入主页','消息进入主页')
        }else {
          this.getUserActive('进入主页','应用进入主页')
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  /deep/ .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
      display: none;
  }
  .YD {// 已读字体
    font-size: 12px;
    margin-left: 10px;
    color: rgba(255,255,255,0.7);
    position: relative;
    top: 10px;
  }
  /deep/ .v-tabs-bar {
    border-radius: 0px;
  }
  .tabs {
    .v-tab {
      min-width: 50px;
      max-width: 70px;
      padding: 0 10px;
    }
    /deep/ .v-tab:before {
      background-color: white;
    }
    /deep/ .v-ripple__container {
      display: none;
    }
    /deep/ .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
    .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
    .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
    .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
      color: #d6d6d6;
      font-size: 16px;
    }
    /deep/ .v-tabs {
      border-bottom: 1px solid #f2f2f2;
    }
    /deep/.v-tab--active {
      // color: inherit;
      font-size: 16px;
      font-weight: 800;
    }

    /deep/ .v-card__title {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.7);
    }
    /deep/ .v-card__text {
      color: white;
    }

    // 内容
    .content {
      background: white;
      padding: 15px;
      padding-top: 0;
    }
  }
  // 第一个卡片的设置
  .dataListOne {
    background: white;
    padding-bottom: 15px;
    padding-top:15px;
    color: white;
    border-bottom: 1px solid #f2f2f2;

  }

  // 列表数据
    .dataList {
      ul {
        padding-left: 0;
        .active {
          padding: 10px 0;        
          border-bottom: 1px solid #f2f2f2;
          font-size: 18px;
          .dataListContent {
            color: #999999;
            line-height: 30px;
            margin-bottom: 5px;
          }
          .dataListDes {
            span {
              color: #999999;
              font-size: 12px;
            }
            span:nth-child(1) {
              margin-right: 10px;
            }
            span:nth-child(2) {
              margin-right: 10px;
            }
          }
        }
        .noactive {
          padding: 10px 0;        
          border-bottom: 1px solid #f2f2f2;
          font-size: 18px;
          .dataListContent {
            line-height: 30px;
            margin-bottom: 5px;
          }
          .dataListDes {
            span {
              color: #999999;
              font-size: 12px;
            }
            span:nth-child(1) {
              margin-right: 10px;
            }
            span:nth-child(2) {
              margin-right: 10px;
            }
          }
        }
      }
    }

  // 预警卡片的设置
  .warnBox {
    padding-top: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  .warnCard {
    /deep/ .v-sheet.v-card:not(.v-sheet--outlined) {
      box-shadow: none;
    }
    padding: 10px;
    border-radius: 0.25rem;
    box-shadow: 0px 0.188rem 1px -0.125rem rgba(0, 0, 0, 0.2), 0px 0.125rem 0.125rem 0px rgba(0, 0, 0, 0.14), 0px 1px 0.313rem 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
    // margin-top: 5px;
    .warnCardTitle {
      font-size: 18px;
      padding-bottom: 20px;
    }
    .warnCardContent {
      padding: 0 10px;
      p {
        text-align: center;
        margin: 0;
        font-size: 14px;
      } 
      p:nth-child(1) {
        color: #ff6d00;
        font-size: 18px;
      }
    }
  }
}
</style>