<template>
    <div class="chartOne">
        <div>
            <v-progress-linear
                buffer-value="100"
                height="15"
                :value="parseInt((Number(data.z_value)/(Number(data.z_value) + Number(data.f_value)))*100)"
                background-color="red"
                color="green"
            ></v-progress-linear>
        </div>
            <div class="chartOneContent">
                <span>{{data.z_name}}</span>
                <span>{{data.f_name}}</span>
            </div>
            <div class="chartOneContent">
                <span>{{data.z_value}}</span>
                <span>{{data.f_value}}</span>
            </div>
            <div class="chartOneContent">
                <span>占比</span>
                <span>占比</span>
            </div>
            <div class="chartOneContent">
                <span>{{parseInt((Number(data.z_value)/(Number(data.z_value) + Number(data.f_value)))*100)}}%</span>
                <span>{{100-Number(parseInt((Number(data.z_value)/(Number(data.z_value) + Number(data.f_value)))*100))}}%</span>
                <!-- <span>{{parseInt(Number(data.z_value)/(Number(data.z_value) + Number(data.f_value)))}}%</span>
                <span>{{parseInt(Number(data.f_value)/(Number(data.z_value) + Number(data.f_value)))}}%</span> -->
            </div>
    </div>
</template>

<script>
export default {
  name: "chartOne",
  components: {
  },
  props: {
    data: {
      type: Object,
      default: {},//该值是否为必须值，true就是没这个值就报错。与default二选一使用
    },
  },
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
.chartOne {
  background: #f2f2f2;
  padding: 15px 10px;
  .chartOneContent {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>