<template>
    <div class="loading-img"  catchtouchmove="preventTouchMove">
        <img src="../assets/timg.gif" alt="">
    </div>
</template>
 
<script>
export default {
    name:'loding',
    methods: {
        
    }
}
</script>
 
<style lang="less" scoped>
.loading-img{
     position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background:rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    // pointer-events: none;
    img{
        width: 25px;
        height: 25px;
    }
    
}
</style>