<template>
    <div class="chartTwo">
        <div class="chartTwoConent" v-for="(item,index) in data" :key="index">
            <span class="text">{{item.project}}</span>
            <!-- <div style="height: 15px;width: 100%;"> -->
              <v-progress-linear
                :buffer-value="getmax(item)"
                height="15"
                :value="getValue(item.value)"
                background-opacity="0"
                :color="index>color.length?color[0]:color[index]"
              ></v-progress-linear>
            <!-- </div> -->
            
            
            <span style="width:80px;padding-left:3px;text-align:right">{{item.value}}</span>
            <!-- <span>{{item}}</span> -->
        </div>
        <!-- <div class="chartTwoConent">
            <span>华南区域</span>
            <v-progress-linear
                buffer-value="100"
                height="15"
                value="31"
                background-opacity="0"
                color="#FA8C16"
            ></v-progress-linear>
            <span>31%</span>
        </div>
        <div class="chartTwoConent">
            <span>上海区域</span>
            <v-progress-linear
                buffer-value="100"
                height="15"
                value="45"
                background-opacity="0"
                color="#FAD102"
            ></v-progress-linear>
            <span>45%</span>
        </div> -->

    </div>
</template>

<script>
export default {
  name: "chartTwo",
  components: {
  },
  props: {
    data: {
      type: Array,
      default: [],//该值是否为必须值，true就是没这个值就报错。与default二选一使用
    },
  },
  data() {
    return {
      color:['#FF4000','#FA8C16','#FAD102']
    };
  },
  methods: {
    getmax(item) {
      let arr = []
      for(let i in this.data) {
        arr.push(this.data[i].value)
      }
      let max = Math.max.apply(null, arr);
      return max
    },
    getValue(value) {
      
      let arr = []
      for(let i in this.data) {
        arr.push(this.data[i].value)
      }
      let max = Math.max.apply(null, arr);
      return parseInt((Number(value)/Number(max))*100)
    },
  },
  mounted() {
  },
  created() {
    
  },
};
</script>

<style lang="less" scoped>
.chartTwo {
  background: #f2f2f2;
  padding: 15px 10px;
  /deep/ .v-progress-linear {
        width: 60%;
    }
  .chartTwoConent {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
    align-items: center;
    .text {
      width: 120px;
    }
  }
}
</style>